<template>
  <div class="mt-4 row">
    <div class="col-md-7">
      <div class="card shadow mt-2">
        <div class="card-body">

          <div class="row">
            <div></div>
            <div class="col-md-12">
              <text-input
                  :value="name.value"
                  :errors="errors.name"
                  :label="name.label"
                  :placeholder="name.placeholder"
                  @type="(v) => (name.value = v)"
                  :is_password="false"
                  :required="true"
              ></text-input>
            </div>
            <div class="col-md-12">
              <text-input
                  :value="kin_name.value"
                  :errors="errors.kin_name"
                  :label="kin_name.label"
                  :placeholder="kin_name.placeholder"
                  @type="(v) => (kin_name.value = v)"
                  :is_password="false"
                  :required="true"
              ></text-input>
            </div>
            <div class="col-md-7">
              <LoadingButton text="Submit" @on-click="saveCategory"></LoadingButton>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import axios from "axios";
import TextInput from "../../components/TextInput";
import LoadingButton from "@/components/Buttons/LoadingButton";
import { alert } from "../../utils/alertUtils";
export default {
  name: "NewCategory",
  components:{
    LoadingButton,
    "text-input": TextInput,
  },
  props:['uuid'],
  data(){
    return{
      category:{},
      name: {
        value: "",
        errors: [],
        label: "Category Name:",
        placeholder: "Enter the category name",
      },
      kin_name: {
        value: "",
        errors: [],
        label: "Kinyarwanda translation Name:",
        placeholder: "Enter Kinyarwanda translation of category name",
      },
      post_data:{
        name:'',
        kin_name:'',
        pctype:'Product'
      },
      errors: {},
    }
  },
  methods:{

    saveCategory:function () {
      let url = `${process.env.VUE_APP_BACKEND_URL}/api/parentCategories`;
      this.post_data.kin_name=this.kin_name.value
      this.post_data.name=this.name.value
      axios
          .post(
              url,
              this.post_data, {
                headers: {
                  Authorization: "Bearer " + this.$store.getters.getToken,
                },
              })
          .then((res) => {
            alert.success_center('Category is saved')
            this.$router.push({ name: "Manage Category", params: { uuid:res.data.uuid } });
          })
          .catch((err) => {
            if (err.response.status === 400) {
              this.errors = err.response.data;
            }else{
              alert.error('Error occurred, try again')
            }
          });
    },

  },
  created() {

  }
}
</script>

<style scoped>

</style>